<template>
  <div class="main_container">
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste {{ module=="levee_fonds"?"dettes":module }}
          </div>
        </div>
      </div>
    </div>
    <div class="table_action">
      <div class="div_left">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
        <button class="btn btn-blue pl-5 pr-5 ml-4 op-btn"
                @click="downloadExport(resultatFiltre.url)"
                download>Exporter</button>
      </div>
    </div>
    <table class="table table-striped mt-4"
           :style="largeur">
      <thead class="mb-0">
        <tr>
          <template v-for="(header,hkey) in tableHeaders">
            <td :key="hkey"
                v-if="header!='id'"
                class="text-center th-blue"
                style="font-size: 0.7em;">{{header}}</td>
          </template>
          
        </tr>
      </thead>
      <tbody v-if="long > 0 && loaded">
        <tr v-for="(resultat, reskey) in resultatFiltre.donnees"
            :key="reskey"
            @click="launchDetails(resultat.id)">
          <template v-for="(propriete,propkey) in objectProps">
            <td :key="propkey"
                v-if="propriete!='id'"
                style="font-size: 0.6em;width:140px">{{ resultat[propriete]}}</td>
          </template>
        </tr>
      </tbody>
    </table>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :module="module"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import ListFilter from "@/components/shared/FilterNew"
import { mapActions, mapGetters, mapMutations } from "vuex"

export default ({
  name:"ResultatFiltre",
  components:{
    // Loader,
    ListFilter,
    // Notif
  },
  data:()=>({
    filtre:[],
    proprietes:[],
    module:"",
    colonneAEnvoyer:[],
    tableHeaders:[],
    objectProps:[],
    exportation:{
      titre:"Situation des marchés de l'entreprise",
      contenu:[],
      entete:[],
      nom_utilisateur:"",
      departement:"",
      date_impression:"",
    },
    long:0,
    loaded:false,
    largeur:"width:500%"
  }),
  created(){
    if (this.$route.params.module) {
      this.module=this.$route.params.module
    }else{
      if(localStorage.getItem("module")){
        this.module=localStorage.getItem("module")
        
      }else{
        this.module="dt"
        
        this.filtre={
          libelle:"programmes.date_debut",
          operation:"",
          nature:"superieur",
          type:"numeric",
          table:"programmes",
          valeur:"01-01-"+new Date().getFullYear(),
          periode:"",
          valeur1:"",
        }
      
        this.filtre={
          libelle:"programmes.date_fin",
          operation:"",
          nature:"superieur",
          type:"numeric",
          table:"programmes",
          valeur:"31-12-"+new Date().getFullYear(),
          periode:"",
          valeur1:"",
        }
        
      }
    }
    var jour = new Date()
    this.exportation.date_impression=""
    if (jour.getDate() < 10) {
      this.exportation.date_impression ="0" 
    }
    this.exportation.date_impression+=jour.getDate()+"-"
    if (jour.getMonth() < 9) {
      this.exportation.date_impression +="0"
    }
    this.exportation.date_impression+=(jour.getMonth()+1)+"-"
    this.exportation.date_impression += jour.getFullYear()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    // remplir la case date_impression pour l'exportation
    this.exportation.date_impression = this.dateJour    
    // remplir la case nom_utilisateur pour l'exportation
    this.exportation.nom_utilisateur = userdetails.nom
    
    // remplir la case departement pour l'exportation
    switch (this.user[0]) {
      case "DT":
        this.exportation.departement = "Direction Technique"
        break
      case "DCG":
        this.exportation.departement = "Direction du Contrôle de Gestion"
        break
      case "DAF":
        this.exportation.departement = "Direction Administrative et Financière/ Ingenierie Financière"
        break
      case "ADMIN":
        this.exportation.departement = "Administrateur"
        break
      default:        
        this.exportation.departement = "Autre"
        break
    }
    localStorage.removeItem("module")
    // this.$refs["modal_button"].click()
  },
  watch:{
    resultatFiltre(){
      if (this.resultatFiltre.donnees) {
        this.exportation.contenu=[]
        // this.exportation.contenu=this.resultatFiltre.donnees
        this.long=this.resultatFiltre.donnees.length
        this.loaded=true
        var count=0
        console.log(this.tableHeaders)
        this.resultatFiltre.donnees.forEach(filtre => {
          this.exportation.contenu.push({})
          this.tableHeaders.forEach(cle => {
            this.exportation.contenu[count][cle]=filtre[cle]
          })
          count++
        })
        console.log(this.resultatFiltre.donnees[0])
        this.exporterDonnees(this.exportation)
                
      }
    },
    proprietes(){
      if(this.proprietes.length >0){
        this.tableHeaders=[]
        this.exportation.entete=[]
        this.colonneAEnvoyer=this.proprietes
        var count=0
        this.proprietes.forEach(propriete => {
          this.tableHeaders.push("")
          var spliter=propriete.split(".")
          this.objectProps.push(spliter)
          if (spliter[1].includes("_")) {
            spliter=spliter[1].split("_")
            for (let index = 0; index < spliter.length; index++) {
              this.tableHeaders[count]+=spliter[index]+" "
            }
          } else {
            this.tableHeaders[count]=spliter[1]
          }
          if (spliter[1]!='id') {
            this.exportation.entete.push(this.tableHeaders[count])
          }
          count++
        })
        // this.exportation.entete=this.tableHeaders
        this.objectProps=[]
        this.proprietes.forEach(propriete => {
          var spliter=propriete.split(".")
          this.objectProps.push(spliter[1])
        })
        // console.log(this.objectProps)
        this.proprietes=[]
        this.setResultatFiltre("")
        
        this.loadFiltres({filtres:this.filtre,proprietes:this.colonneAEnvoyer,module:this.module})
      }
    }
  },
  computed:{
    ...mapGetters(["resultatFiltre","msgFailResultatFiltre","exportLink","failExportLink"]),
  },
  methods:{
    ...mapActions(["loadFiltres","exporterDonnees"]),
    ...mapMutations(["setResultatFiltre","setMsgFailResultatFiltre"]),
    filtrer() {
      // this.loaded = false
      // this.long = null
      
      this.setResultatFiltre("")
      this.loadFiltres({filtres:this.filtre,proprietes:this.colonneAEnvoyer,module:this.module})
    },
    launchDetails(id){
      switch (this.module) {
        case "decomptes":
          this.$router.push({ name: "DecDetail", params: { id: id } })
          break
        case "marches":
          this.$router.push({ name: "Detail", params: { id: id } })
          break
        case "programmes":
          this.$router.push({ name: "DetailProgramme", params: { id: id } })
          break
        case "levee_fonds":
          this.$router.push({ name: "DetailLevee", params: { id: id } })
          break
        default:
          break
      }
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+"/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
})
</script>
