<template>
  <div class="row">
    <div class="col-md-2"
         style="border-right:1px solid grey;">
      <ul>
        <li @click="changeOption('table')"
            v-if="module=='dt'">Tables</li>
        <li @click="changeOption('colonne')">Colonnes</li>
        <li @click="changeOption('filtre')">Filtre</li>
      </ul>
    </div>
    <div class="col-md-10"
         v-if="option=='table'">
      <form class="form">
        <div class="form-check"
             v-for="(table,takey) in listTable"
             :key="takey">
          <label class="form-check-label">
            <input type="checkbox" 
                   class="form-check-input" 
                   name="" 
                   id="" 
                   :value="table.bddLibelle"
                   v-model="tables"
                   checked>
            {{ table.libelle }}
          </label>
        </div>
      </form>    
    </div>
    <div class="col-md-10"
         v-if="option=='colonne'">
      <!-- <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <div class="form-check">
            <label class="form-check-label">
              <input type="checkbox" 
                     class="form-check-input" 
                     name="" 
                     id=""
                     ref="all"
                     checked
                     @click="toggleAll()">
              Selectionner tout
            </label>
          </div>
        </div>
      </div> -->
      <div class="row">
        <template v-for="(tab,tabkey) in listChamps">
          <div class="col-md-4 mb-4"
               :key="tabkey">
            <h5 class="text-center text-uppercase"
                v-if="tab!='activites' && tab!='activites_programmes'">{{ tab.table }}</h5>
            <h5 class="text-center text-uppercase"
                v-else>activites</h5>
            <div class="form-check"
                 v-for="(champ,chakey) in tab.champs"
                 :key="chakey">
              <label class="form-check-label"
                     v-if="champ.Field!='id' && champ.Field!='created_at' && champ.Field!='updated_at'&& champ.Field!='deleted_at'" >
                <input type="checkbox" 
                       class="form-check-input" 
                       name="" 
                       id=""
                       :ref="'checkbox'+tab.table+'.'+champ.Field" 
                       :value="''+tab.table+'.'+champ.Field+''"
                       checked
                       @click="toggleColumn(tab.table,champ)">
                {{ champ.Field }}
              </label>
            </div>
          </div>  
        </template>
      </div>
      
    </div>
    <div class="col-md-10"
         v-if="option=='filtre'">
      <form>
        <template v-for="(filtre,filkey) in filtres">
          <div class="form-row"
               :key="filkey">
            <label for="">Filtre n°{{filkey+1}}</label>
          </div>
          <div class="form-row"
               :key="'row'+filkey">
            <div class="form-group col-md-3">
              <select class="form-control"
                      v-model="filtre.libelle"
                      @change="addType(filtre)">
                <option value="">---Choisissez un champ---</option>
                <option v-for="(champ,chmpkey) in champs" 
                        :key="chmpkey"
                        :value="champ.valeur">{{champ.libelle}}</option>
              </select>
            </div>
            <div class="form-group col-md-3"
                 v-if="filtre.libelle != ''">
              <select class="form-control"
                      v-model="filtre.nature"
                      v-if="filtre.type=='caractere'">
                <option value="">---Choisissez une option---</option>
                <option :value="'commence_par'">Commence par</option>
                <option :value="'termine_par'">Se termine par</option>
                <option :value="'contient'">Qui contient</option>
                <option :value="'egale'">Egale à</option>
              </select>
              <select class="form-control"
                      v-model="filtre.operation"
                      v-if="filtre.type=='date'">
                <option value="">---Choisissez une option---</option>
                <option :value="'superieur'">Supérieur</option>
                <option :value="'superieur_egale'">Supérieur ou égale</option>
                <option :value="'inferieur'">Inférieur</option>
                <option :value="'inferieur_egale'">Inférieur ou égale</option>
                <option :value="'egale'">Egale</option>
                <option :value="'interval'">Intervalle</option>
              </select>
              <select class="form-control"
                      v-model="filtre.nature"
                      v-if="filtre.type=='numeric'">
                <option value="">---Choisissez une option---</option>
                <option :value="'superieur'">Supérieur</option>
                <option :value="'superieur_egale'">Supérieur ou égale</option>
                <option :value="'inferieur'">Inférieur</option>
                <option :value="'inferieur_egale'">Inférieur ou égale</option>
                <option :value="'egale'">Egale</option>
              </select>
            </div>
            <div class="form-group col-md-3"
                 v-if="filtre.type == 'date'">
              <select class="form-control"
                      v-model="filtre.periode">
                <option value="">---Choisissez une période---</option>
                <option :value="'jour'">Jour</option>
                <option :value="'mois'">Mois</option>
                <option :value="'annee'">Année</option>
              </select>
            </div>
            <div class="form-group col-md-3"
                 v-if="filtre.nature != '' || (filtre.operation !='interval' && filtre.operation !='')">
              <input type="text"
                     class="form-control"
                     placeholder="Inscrivez une valeur"
                     v-model="filtre.valeur"
                     @change="saveFilter()">
            </div>
            <div class="form-group col-md-3"
                 v-if="filtre.operation == 'interval'">
              <input type="text"
                     class="form-control"
                     placeholder="De:"
                     v-model="filtre.valeur"
                     @change="saveFilter()">
              <input type="text"
                     class="form-control"
                     placeholder="à:"
                     v-model="filtre.valeur1"
                     @change="saveFilter()">
            </div>
          </div>  
        </template>  
      </form>
    </div>
  </div>
</template>
<style>
.form-row input[type="text"],
.form-row input[type="number"],
.form-row input[type="date"],
.form-row input[type="email"],
label,
.form-row select,
button{
  font-size: 0.6em;
}

.form-row select option{
  font-size: 0.8em;
}
input,select,select option{
  height: fit-content;
}
</style>
<script>
// import utils from "@/assets/js/utils"
import { mapActions,mapGetters,mapMutations } from "vuex"
export default ({
  name:"ListFilter",
  props:["module"],
  data:()=>({
    filtres:[
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
      {
        libelle:"",
        operation:"",
        nature:"",
        type:"",
        table:"",
        valeur:"",
        periode:"",
        valeur1:"",
      },
    ],
    option:"filtre",
    listTable:[
      {libelle:"Programme",bddLibelle:"programmes"},
      {libelle:"Rubrique",bddLibelle:"rubriques"},
      {libelle:"Activité",bddLibelle:"activites_programmes"},
      {libelle:"Marché",bddLibelle:"marches"},
      {libelle:"Décomptes",bddLibelle:"decomptes"},
      {libelle:"Décaissements",bddLibelle:"decaissements"},
    ],
    champs:[],
    listChamps:[],
    tables: [],
    tableColonne:""
  }),
  watch:{
    tables(){
      console.log(this.tables)
      this.listChamps=[]
      this.setColonnes("")
      this.loadColonnes(this.tables)
    },
    listChamps(){
      this.listChamps.forEach(list => {
        var count=0
        list.champs.forEach(champ =>{
          if ( champ.Field!="created_at" && champ.Field!="updated_at" && champ.Field!="deleted_at") {
            this.champs.push({
              libelle:champ.Field,
              valeur:list.table+"."+champ.Field,
              type:"",
              table:list.table,
            })
            if (champ.Type=="bigint(20) unsigned" || champ.Type=="bigint(20)" ||champ.Type=="int(11)" || champ.Type=="decimal(40,20)") {
              this.champs[count].type="numeric"
            } else {
              if (champ.Type=="timestamp" || champ.Type=="date") {
                this.champs[count].type="date"
              }else{
                this.champs[count].type="caractere"
              }
            }
            count++
          }
        })
      })
      if(this.module== "dt"){
        this.filtres[0]={
          libelle:"date_debut",
          operation:"superieur_egale",
          nature:"",
          type:"date",
          table:"programmes",
          valeur:"01-06-"+new Date().getFullYear(),
          periode:"",
          valeur1:"",
        }
      
        this.filtres[1]={
          libelle:"date_fin",
          operation:"inferieur_egale",
          nature:"",
          type:"date",
          table:"programmes",
          valeur:"31-12-"+new Date().getFullYear(),
          periode:"",
          valeur1:"",
        }
      }
      this.saveFilter()
      console.log(this.champs)
      console.log(this.$refs['checkboxmarches.libelle'])

    },
    colonnes(){
      if (this.colonnes.donnees) {
        // this.listChamps = this.colonnes.donnees
        var count=0
        this.colonnes.donnees.forEach(element => {
          this.listChamps.push({table:element.table,champs:[]})
          element.champs.forEach(champ => {
            if (champ.Type!="longtext") {
              this.listChamps[count].champs.push(champ)
            }
          })
          count++
        })
        console.log(this.listChamps)
      }
    }
  },
  created(){
    this.tableColonne=this.module
    if (this.module!="dt") {
      this.tables.push(this.module)
    }else{
      this.tables=["programmes","rubriques","activites","activites_programmes","marches","decomptes","decaissements"]
    }
    console.log(this.tables)
    this.listChamps=[]

  },
  computed:{
    ...mapGetters(["colonnes","colonnesFail"])
  },
  methods:{
    ...mapActions(["loadColonnes"]),
    ...mapMutations(["setColonnes","setColonnesFail"]),

    addType(filtre){
      filtre.nature=""
      filtre.operation=""
      filtre.valeur=""
      filtre.valeur1=""
      this.champs.forEach(champ => {
        if (champ.valeur == filtre.libelle) {
          filtre.type=champ.type
          filtre.table=champ.table
        }
      })
    },
    changeOption(option){
      this.option=""
      this.option=option
    },
    saveFilter(){
      this.$parent.filtre=[]
      this.filtres.forEach(element => {
        if (element.libelle !='' && (element.nature !='' || element.operation != '')) {
          this.$parent.filtre.push(element)
        }
      })
      console.log(this.filtres)
      this.champs.forEach(element => {
        this.$parent.proprietes.push(element.valeur)
      })
    },
    toggleColumn(table,colonne){
      console.log(this.$refs["checkbox"+table+"."+colonne.Field])
      this.champs=[]
      this.listChamps.forEach(list => {
        var count=0
        list.champs.forEach(champ =>{
          if (champ.Field!="id" && champ.Field!="created_at" && champ.Field!="updated_at" && champ.Field!="deleted_at" && (this.$refs["checkbox"+list.table+"."+champ.Field][0].checked)) {
            this.champs.push({
              libelle:champ.Field,
              valeur:list.table+"."+champ.Field,
              type:"",
              table:list.table,
            })
            if (champ.Type=="bigint(20) unsigned" || champ.Type=="bigint(20)" ||champ.Type=="int(11)" || champ.Type=="decimal(40,20)") {
              this.champs[count].type="numeric"
            } else {
              if (champ.Type=="timestamp" || champ.Type=="date") {
                this.champs[count].type="date"
              }else{
                this.champs[count].type="caractere"
              }
            }
            count++
          }
        })
      })
      this.saveFilter()
      console.log(this.champs.length)
    },
    toggleAll(){
      console.log(this.$refs["all"].checked)
      if(!this.$refs["all"].checked){
        this.$refs["all"].checked=""
        this.listChamps.forEach(list => {
          list.champs.forEach(champ =>{
            if (champ.Field!="id" && champ.Field!="created_at" && champ.Field!="updated_at" && champ.Field!="deleted_at") {
              this.$refs["checkbox"+list.table+"."+champ.Field][0].checked=false  
            }
          })
        })
      }else{
        this.$refs["all"].checked=false
        console.log("$refs")
        console.log(this.$refs["all"].checked)
        this.listChamps.forEach(list => {
          list.champs.forEach(champ =>{
            if (champ.Field!="id" && champ.Field!="created_at" && champ.Field!="updated_at" && champ.Field!="deleted_at") {
              this.$refs["checkbox"+list.table+"."+champ.Field][0].checked=true  
            }          
          })
        })
      }
    }
  }
})
</script>
